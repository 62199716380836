import "./App.css";
import Background from "./components/background/background.js";
import { Button, NavLink } from "reactstrap";
import me from "./static/10.22-me.jpeg"; // for Amplify, need to add "jpeg" to rewrite rule to render (pdf too!)
import React from "react";

const buttons = [
  {
    link: "https://www.linkedin.com/in/aqdnguyen/",
    icon: "bi-linkedin",
    text: "LinkedIn",
  },
  {
    link: "https://www.github.com/aqdnguyen/",
    icon: "bi-github",
    text: "GitHub",
  },
  {
    link: "resume-anguyen.pdf",
    icon: "bi-briefcase-fill",
    text: "Experience",
    open: { target: "_blank" }
  },
]

function App() {
  return (
    <div className="App">
      <Background />
      <header className="App-header">
        <h1 className="display-1">Anthony Nguyen</h1>
        <div className="my-btns">
          {buttons.map((b, index) => (
            <Button key={index} outline color="light" size="lg" href={b.link} {...b.open}>
              <i className={`bi px-1 ${b.icon}`} ></i>
              {b.text}
            </Button>
          ))}

          <NavLink href="#bio" className="arrow-nav">
            <i className="bi bi-arrow-down-square display-6"></i>
          </NavLink>

        </div>

      </header>
      <div className="content-section">
        <h1 id="bio">Bio</h1>
        <img className="img-me" src={me} alt="me standing in a pumpkin patch!"></img>
        <div>
          Thanks for stopping by! A little bit about myself, I'm currently a software engineer at Healthsparq. There, I help build and maintain healthcare transparency tools that are stable, secure and compliant.
          <br /><br />
          Aside from work, I enjoy spending time with my wife and baby boy. I can definitely say I'm no longer a beginner at changing diapers and cleaning spit up.

          You can also find me doing DIY projects for my home. It's always satisfying to see the before and after and the journey it took to get there.           {/* <br /><br /> */}
          My more casual activities include playing video games and exercising.
        </div>
      </div>

    </div>
  );
}

export default App;
